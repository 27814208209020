<template>
  <div class="top-page">
    <div class="bg-overlay valign-wrapper">
      <div class="row">
        <div class="col s12 xl5 left-side center"><img src="@/assets/screenshots.png"></div>
        <div class="col s12 xl7 right-side">
          <div class="slogan">{{ $t('landing.slogan')}}</div>
          <div class="info">{{ $t('landing.info')}}</div>
          <button-bar>
            <anchor-button anchor="#how">{{ $t('landing.cta')}}</anchor-button>
            <anchor-button anchor="#products" hollow="true">{{ $t('landing.shop')}}</anchor-button>
          </button-bar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnchorButton from "@/components/AnchorButton.vue";
import ButtonBar from "@/components/ButtonBar.vue";

export default {
  name: "TopPage",
  components: {ButtonBar, AnchorButton}
}
</script>

<style scoped lang="scss">
.top-page {
  background-image: url("/bgoffice.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media only screen and (min-width: $medium-screen-up) {height: calc(100vh - 64px)};
  @media only screen and (max-width: $small-screen) {height: calc(100vh - 56px)};
  color: white;
}
.bg-overlay {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7125) 0%, rgba(0, 0, 0, 0.6375) 34.9%, rgba(0, 0, 0, 0.525) 64.06%, rgba(0, 0, 0, 0.45) 100%);
  height: 100%;
}
.left-side {text-align: center}
.left-side img {width: 90%; max-width: 550px}
.right-side {
  text-align: center;
  @media only screen and (min-width: $large-screen) {padding-right: 5em};
}
.slogan {font-size: 36px; padding-bottom: 3rem}
.info {font-size: 30px; font-style: italic; padding-bottom: 3rem}
@media only screen and (max-width: $small-screen) {
  .slogan {font-size: 24px}
  .info {font-size: 20px; font-style: italic}
}
</style>

<template>
  <div>
    <navbar/>
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import Navbar from "@/components/Navbar"
import {useRoute} from "vue-router"
import {nextTick, onMounted, watch} from "vue"
import http from "@/http"
import i18n from "@/i18n";

const lang = Object.fromEntries(new URLSearchParams(location.search)).lang
if (lang)
  i18n.setLocale(lang)
let sessionId = new Date().getTime()
const route = useRoute()
watch(() => route.path, (newRoute) => nextTick(() => onRouteChange(newRoute)))
const onRouteChange = (newRoute) => {
  const path = encodeURIComponent(newRoute)
  http.get(`${process.env.VUE_APP_TRACKURL}?time=${sessionId}&p=${path}`)
  setTimeout(() => window.scrollTo(0,0), 250) // ilma selleta ei lähe miskipärast vaate algusesse
}
onMounted(() => {
  if (location.pathname === '/')
    http.get(`${process.env.VUE_APP_TRACKURL}?time=${sessionId}&p=%2F`)
})
</script>

<style lang="scss">
@import '~materialize-css/sass/materialize';

* {
  font-family: 'Lato', sans-serif !important;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  margin-top: 0;
  color: $text-color;
}
#app h1 {font-weight: bold; width: 100%; text-align: center; margin-top: 20rem}
div {scroll-margin-top: 80px;}//et a-href linkides ei jääks navbari varju
.collection {border: 0;}
.collection .collection-item.avatar {min-height: 4rem}
.collection .collection-item.active {color: $secondary-color; background-color: #fff}
a, a svg.svg-inline--fa {color: $secondary-color; cursor: pointer}
a:hover, a:hover svg.svg-inline--fa {text-decoration: underline; color: lighten($secondary-color, 15%); transition: all 0.2s linear}
a svg.svg-inline--fa {margin: auto; height: 100%; font-size: 1.6rem}
input, textarea, select {color: $text-color}
.toast {background-color: $success-color; margin-left: 2rem; margin-right: 2rem}
.toast svg {margin-right: 1rem}
.btn-floating {text-align: center}
button svg {margin-right: 1rem}
.error-toast {color: white; background-color: $error-color; margin: 2rem}
.progress-container, .progress {margin: 0; height: 6px}

.fade-leave-active {transition: opacity 0s}//kaob koheselt, sest muidu tekkis häiriv hüppamine
.fade-enter-active {transition: opacity 1s ease}
.fade-enter-from, .fade-leave-to {opacity: 0}
</style>

export default {
	navbar: {
		products: 'Tooted',
		how: 'Kuidas toimib',
		advantages: 'Eelised',
		contact: 'Kontakt',
	},
	landing: {
		slogan: 'Lisa läbipääsuväravale kleepsu või piletikontroll',
		cta: 'Tutvu',
		shop: 'Osta',
		info: 'Pakume piletikontrolli võimekust lihtsa ja kiirpaigaldatava standardmooduliga',
	},
	how: {
		title: 'Kuidas Skemteki moodul töötab',
		title2: 'Mooduli paigadus on ülilihtne',
		wireup: 'Ühenda',
		wireup2: 'kaablitega',
		settings: 'Seadista',
		settings2: 'telefoni kaudu',
		validate: 'Alusta',
		validate2: 'väravas piletite valideerimist',
		info1: 'Moodul annab läbipääsuväravale pileti valideerimise võimekuse. Skemteki mooduli puhul puudub vajadus kuluka ja ajamahuka arendustöö järele. Paigaldusel on vaja ühendada kaablid ja teha vaid lihtne seadistus telefoni või veebibrauseriga.',
		info2: 'Tihtipeale soovivad kliendid lisada enda läbipääsuväravatele automaatse piletikontrolli võimekuse. Selline piletikontroll peab liidestuma kas kassadega, keskse piletimüügisüsteemiga või ettevõtte mõne infosüsteemiga (ERP, mikroteenused). Sellest hoolimata puudub see võimekus paljudel tööstuslikel väravatel, mistõttu integratsioon osutub suureks peavaluks.',
		info3: 'Eristu konkurentidest, paigaldades väravale Skemtek-moodul. Nii hoiad kiirintegratsiooniga kulusid kokku!',
	},
	why: {
		title: '8 Reasons to Choose Skemtek Module',
		reason1: 'Usaldusväärne ja hästi testitud paljudes integratsioonides',
		reason2: 'Toetab moodsaid integratsioonimeetodeid: HTTP/JSON, HTTP/XML, REST, SOAP, mikroteenused',
		reason3: 'Vähene või olematu arendusvajadus - kohe valmis paigalduseks',
		reason4: 'Moodulil on intuitiivne brauseripõhine seadistusliides',
		reason5: 'Moodulil on lihtne brauseripõhine veaotsingu liides',
		reason6: 'Oht tarnega tähtajast üle minna on pea olematu',
		reason7: 'Eelarve ületamise ohtu pole - moodulitel on fikseeritud hind',
		reason8: 'Skemteki moodul on loodud piletite valideerimiseks ja kohapealset kaabeldust on võimalikult vähe',
		title1: 'Hästi testitud',
		title2: 'Integratsioonivalmis',
		title3: 'Pole vaja tarkvara arendust',
		title4: 'Lihtne seadistada',
		title5: 'Lihtne veaotsing',
		title6: 'Kindel tähtaeg',
		title7: 'Kindel hind',
		title8: 'Minimaalne kaabeldus',
	},
	contact: {
		title: 'Kontakt',
		location: 'Kandle 3, Tänassilma, Harjumaa 76406',
		legal: 'Miltema OÜ, reg.nr 10947918, KMKR EE100827840',
	},
	vatinfo: '* lisandub käibemaks; toodud hinnad on kogusele 3-10tk',
	products: {
		title: 'Skemtek mooduli 3 pakkumist',
		order: 'Telli siit',
		customizable: 'seadistatav',
		customizableSplit: 'seadis- tatav',
		feature1: 'Valideerimisteenuseid',
		feature2: 'RFID, ribakoodi, ruutkoodi lugerite toetus (USB)',
		feature3: 'Värviline LCD komplektis',
		feature4: 'Animeeritud tagasiside displeil',
		feature5: 'Personaalsed tervitused',
		feature6: 'HTTPS/JSON, REST, SOAP API integratsioon',
		feature7: 'Mikroteenuste integratsioon',
		feature8: 'Toiteplokk komplektis',
		feature9: '10A/250VAC releed lampide juhtimiseks',
		feature10: 'Intuitiivne brauseripõhine seadistusliides',
		feature11: 'Brauseripõhine veaotsing',
		showAll: 'Näita kõiki',
		how: {
			subtitle: 'Kuidas GATE-moodulid töötavad',
			link: 'GATE moodulite mudelid ja hinnad',
		},
	},
	footer: {
		websocketLost: 'Ei saa serveriga ühendust',
	},
}

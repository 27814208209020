<template>
  <div class="button-bar"><slot/></div>
</template>

<script>
export default {
  name: "ButtonBar"
}
</script>

<style scoped>
.button-bar {display: flex; justify-content: space-evenly}
</style>

<template>
  <div>
    <div>
      <h1>{{ $t('why.title') }}</h1>
    </div>

    <div class="reasons-container">
      <div class="row">
        <div class="col s12 xl4 offset-xl4 reason">
          <div class="reason-title">{{ $t('why.title1') }}</div>
          <div class="reason-text">{{ $t('why.reason1') }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col s12 xl5 left-side">
          <div class="reason">
            <div class="reason-title">{{ $t('why.title2') }}</div>
            <div class="reason-text">{{ $t('why.reason2') }}</div>
          </div>
          <div class="reason middle-reason">
            <div class="reason-title">{{ $t('why.title3') }}</div>
            <div class="reason-text">{{ $t('why.reason3') }}</div>
          </div>
          <div class="reason">
            <div class="reason-title">{{ $t('why.title4') }}</div>
            <div class="reason-text">{{ $t('why.reason4') }}</div>
          </div>
        </div>
        <div class="col s12 xl2">
        </div>
        <div class="col s12 xl5 right-side">
          <div class="reason">
            <div class="reason-title">{{ $t('why.title5') }}</div>
            <div class="reason-text">{{ $t('why.reason5') }}</div>
          </div>
          <div class="reason middle-reason">
            <div class="reason-title">{{ $t('why.title6') }}</div>
            <div class="reason-text">{{ $t('why.reason6') }}</div>
          </div>
          <div class="reason">
            <div class="reason-title">{{ $t('why.title7') }}</div>
            <div class="reason-text">{{ $t('why.reason7') }}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col s12 xl4 offset-xl4 reason">
          <div class="reason-title">{{ $t('why.title8') }}</div>
          <div class="reason-text">{{ $t('why.reason8') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.reasons-container {
  background-image: url("/module_small.png");
  background-repeat: no-repeat;
  background-position: center;
  background-position: top;
}
.reasons-container .row {margin-bottom: 0}
.reason {text-align: center; margin-top: 2rem}
.reason-title {color: $secondary-color; font-weight: bold; font-size: 1.25rem}

@media only screen and (max-width: $large-screen) {
  .reasons-container {padding-top: 220px;}
}
@media only screen and (min-width: $large-screen) {
  .left-side .reason:first-child, .right-side .reason:first-child {margin-top: 0}
  .reasons-container {
    background-position: center;
  }
  .left-side {
    padding-right: 3rem;
    .reason {margin-left: 5rem}
    .reason.middle-reason {margin-left: 0; margin-right: 5rem}
  }
  .right-side {
    padding-left: 3rem;
    .reason {margin-right: 5rem}
    .reason.middle-reason {margin-right: 0; margin-left: 5rem}
  }
}
</style>

<template>
  <div class="lang-picker">
    <a title="English" @click="ch('en')" :class="{active: locale==='en'}">EN</a>
    <span>|</span>
    <a title="Eesti" @click="ch('et')" :class="{active: locale==='et'}">ET</a>
  </div>
</template>

<script setup>
import i18n from "@/i18n"
import {defineProps, onMounted, ref} from "vue"
defineProps({center: {type: Boolean, default: true}, darkBg: {type: Boolean, default: true}})
const locale = ref(null)
onMounted(() => locale.value = i18n.getLocale())
function ch(newLocale) {
  i18n.setLocale(newLocale)
  locale.value = newLocale
  localStorage.setItem('locale', newLocale)
  document.querySelector('html').setAttribute('lang', newLocale)
  return false
}
</script>

<style scoped lang="scss">
#app .lang-picker {margin: 0 0.5rem 0 1rem; display: flex; flex-flow: row nowrap}
.lang-picker .active {font-weight: bold}
.lang-picker a {cursor: pointer; padding: 0 4px 0 4px}
</style>

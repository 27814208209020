import { createApp } from 'vue'
import App from './App.vue'
import router from '@/routes'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import mixin from '@/mixin'
import i18n from "@/i18n";

window.router = router
const app = createApp(App)

app.mixin(mixin).use(router).use(i18n).mount('#app')


<template>
  <div id="how" class="how">
    <h1>{{ $t('how.title') }}</h1>
    <div>
        <video controls>
          <source src="@/assets/gate-en.mp4" type="video/mp4">
        </video>
      <div class="center three-step-title">{{ $t('how.title2') }}</div>
      <div class="col s12 three-step center">
        <div class="center step">
          <div class="step-icon"><img src="@/assets/wireup.png"></div>
          <div class="step-title">{{ $t('how.wireup') }}</div>
          <div class="step-title-trailer">{{ $t('how.wireup2') }}</div>
        </div>
        <div class="center step">
          <div class="step-icon"><img src="@/assets/settings.png"></div>
          <div class="step-title">{{ $t('how.settings') }}</div>
          <div class="step-title-trailer">{{ $t('how.settings2') }}</div>
        </div>
        <div class="center step">
          <div class="step-icon"><img src="@/assets/validate.png"></div>
          <div class="step-title">{{ $t('how.validate') }}</div>
          <div class="step-title-trailer">{{ $t('how.validate2') }}</div>
        </div>
      </div>
      <div class="center how-info">{{ $t('how.info1') }}</div>
      <div class="center how-info">{{ $t('how.info2') }}</div>
    </div>
  </div>

</template>

<style scoped>
.how div {font-size: 20px}
video {width: 99%; max-height: 50vh}
.three-step-title {margin-top: 2rem; margin-bottom: 1rem}
.three-step {display: inline-flex; width: 100%}
.step {flex: 1 100%}
.step-title {font-weight: bold}
.step-icon img {height: 3.5rem}
.how-info {margin-top: 3rem}
</style>

import M from 'materialize-css'
import http from '@/http'

window.isMounted = false // vältimaks Mixin.mounted() korduvaktiveerimist
window.isCreated = false // vältimaks Mixin.created() korduvaktiveerimist
window.routeChanged = false // vältimaks vaate vahetumisel tegevuste dubleerimist

export default {
	methods: {
		updateWidgets() {
			M.updateTextFields()//text inputtide labelid väikseks
			M.Tooltip.init(document.querySelectorAll('.tooltipped'), {enterDelay: 500, exitDelay: 1000})
			M.Tabs.init(document.querySelectorAll('.tabs'))
		},
		setInputFocus() {
			const widgets = document.querySelectorAll('textarea, input')
			if (widgets != null && widgets.length > 0) widgets[0].focus()
		},
		hideSpinner() {document.getElementById("loading_progress").style.display = 'none'},
		internalPreSendRequest(config) {
			this.$root.errors = {}
			const jwt = window.localStorage.getItem('jwt')
			if (jwt)
				config.headers.Authorization = `Bearer ${jwt}`
		},
		routeChanged() {
			window.routeChanged = false
			this.updateWidgets()
			setTimeout(this.setInputFocus, 100)//mõnikord on juba 100ms widgetid valmis
			setTimeout(this.setInputFocus, 500)//aga mõnikord läheb rohkem aega
			this.$nextTick(this.updateWidgets)
			setTimeout(this.updateWidgets, 500)
		},
	},
	mounted() {
		if (window.isMounted)
			return
		window.isMounted = true
		M.AutoInit(null)//autoinit aktiveeri ainult ühe korra lehe laadimisel
	},
	created() {
		if (window.isCreated)
			return
		window.isCreated = true
		http.initInterceptors({
			sendRequest: this.internalPreSendRequest,
			responseOk: () => {},
			response422: error => this.$root.errors = error.response.data.fieldErrors,
			responseOtherError: () => {},
			timeout: () => {},
		})
	},
	watch: {
		'$route'() {
			if (!window.routeChanged) {
				window.routeChanged = true
				this.$nextTick(this.routeChanged)
			}
		}
	}
}

<template>
  <div class="contact">
    <div class="row">
      <div class="col s12 l6 details side valign-wrapper">
        <ul>
          <li><div class="icon"/><img src="@/assets/skemtek-logo.svg" id="contact" class="skemtek-logo hide-on-small-and-down"></li>
          <li><div class="icon"><i class="fas fa-phone"></i></div> <a href="tel:+3725114741">+372 51 14741</a></li>
          <li><div class="icon"><i class="fas fa-envelope"></i></div> <a href="mailto:info@skemtek.com"><span>info</span>&#64;<span>skemtek.com</span></a></li>
          <li><div class="icon"><i class="fab fa-linkedin"></i></div> <a href="https://www.linkedin.com/in/margus-selge/">https://www.linkedin.com/in/margus-selge/</a></li>
          <li><div class="icon"><i class="fas fa-location-dot"></i></div> {{ $t('contact.location') }}</li>
          <li><div class="icon"><i class="fas fa-suitcase"></i></div> {{ $t('contact.legal') }}</li>
        </ul>
      </div>
      <div class="col s12 l6 qr side valign-wrapper">
        <img src="@/assets/qrcode.svg">
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.contact .row {margin-bottom: 0}
.side {height: 100vh}
@media only screen and (max-width: $small-screen) {
  .side {height: 49vh}
  .details li {text-align: center}
}
.side, .side a {color: white}
.details {
  .icon {width: 3rem; display: inline-block}
  .skemtek-logo {margin-bottom: 1rem}
  background-color: $primary-color;
  ul {
    margin-left: auto; margin-right: auto;
    img {height: 2rem}
  }
  li {line-height: 3rem}
  li svg.svg-inline--fa {height: 1.5rem; vertical-align: sub; margin-right: 1rem}
}
.qr {
  background-color: darken($primary-color, 10%);
  img {margin-left: auto; margin-right: auto}
}
</style>

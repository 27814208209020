<template>
<div id="products" class="products" :class="{'products-gap': !showAll}">
  <h1>{{ $t('products.title') }}</h1>

  <table class="striped highlight centered">
    <thead>
      <tr class="prices-header">
        <th/>
        <th>
          <div class="model">G-Basic</div>
          <div class="price">€259</div>
          <div class="brief hide-on-med-and-down">{{ $t('products.brief1') }}</div>
        </th>
        <th>
          <div class="model">G-Multi</div>
          <div class="price">€339</div>
          <div class="brief hide-on-med-and-down">{{ $t('products.brief2') }}</div>
        </th>
        <th>
          <div class="model">G-Disp</div>
          <div class="price">€479</div>
          <div class="brief hide-on-med-and-down">{{ $t('products.brief3') }}</div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="row-head">{{ $t('products.feature1') }}</td>
        <td>1</td><td>1-10</td><td>1-10</td>
      </tr>
      <tr>
        <td class="row-head">{{ $t('products.feature2') }}</td>
        <td>1</td><td>1</td><td>1-2</td>
      </tr>
      <tr>
        <td class="row-head">{{ $t('products.feature10') }}</td>
        <td><present-icon/></td><td><present-icon/></td><td><present-icon/></td>
      </tr>
      <tr v-if="showAll">
        <td class="row-head">{{ $t('products.feature6') }}</td>
        <td><present-icon/></td><td><present-icon/></td><td><present-icon/></td>
      </tr>
      <tr v-if="showAll">
        <td class="row-head">{{ $t('products.feature7') }}</td>
        <td><present-icon/></td><td><present-icon/></td><td><present-icon/></td>
      </tr>
      <tr v-if="showAll">
        <td class="row-head">{{ $t('products.feature8') }}</td>
        <td><present-icon/></td><td><present-icon/></td><td><present-icon/></td>
      </tr>
      <tr v-if="showAll">
        <td class="row-head">{{ $t('products.feature9') }}</td>
        <td>1-4</td><td>1-4</td><td>1-4</td>
      </tr>
      <tr v-if="showAll">
        <td class="row-head">{{ $t('products.feature11') }}</td>
        <td><present-icon/></td><td><present-icon/></td><td><present-icon/></td>
      </tr>
      <tr>
        <td class="row-head">{{ $t('products.feature3') }}</td>
        <td><missing-icon/></td><td><missing-icon/></td><td>5"</td>
      </tr>
      <tr v-if="showAll">
        <td class="row-head">{{ $t('products.feature4') }}</td>
        <td><missing-icon/></td><td><missing-icon/></td>
        <td>
          <span class="hide-on-small-and-down">{{ $t('products.customizable') }}</span>
          <span class="hide-on-med-and-up">{{ $t('products.customizableSplit') }}</span>
        </td>
      </tr>
      <tr v-if="showAll">
        <td class="row-head">{{ $t('products.feature5') }}</td>
        <td><missing-icon/></td><td><missing-icon/></td><td><present-icon/></td>
      </tr>
      <tr v-if="!showAll" class="show-all">
        <td><a href @click.prevent="revealAll">{{ $t('products.showAll') }}</a></td>
        <td colspan="3"></td>
      </tr>
    </tbody>
  </table>

  <vat-info/>

  <button-bar>
    <anchor-button anchor="#contact">{{ $t('products.order') }}</anchor-button>
  </button-bar>
</div>
</template>

<script setup>
import MissingIcon from "@/components/widgets/MissingIcon";
import PresentIcon from "@/components/widgets/PresentIcon";
import VatInfo from "@/components/VatInfo";
import {ref} from "vue";
import AnchorButton from "@/components/AnchorButton.vue";
import ButtonBar from "@/components/ButtonBar.vue";

const showAll = ref(false)
const revealAll = () => showAll.value = true;
</script>

<style scoped lang="scss">
.products {margin-bottom: 10rem}
.products-gap {margin-bottom: 50vh}
.common-features {border: 1px solid $border-color; border-radius: 4px; padding: 0.5rem; margin-top: 0.5rem}
td.row-head {text-align: right}
.price {color: $secondary-color; font-size: 1.5rem}
.brief {font-weight: lighter; color: lighten($primary-color, 25%); padding: 0 .5rem}
@media only screen and (max-width: $small-screen) {
  .model {
    font-weight: normal;
    font-size: 14px;
  }
}
tr:hover {background-color: white}
th {vertical-align: top}
.show-all td {text-align: right; background-color: white}
.show-all a {
  color: $primary-color;
  font-weight: bold;
  padding: .5rem 2rem;
  border-top: 1px solid $border-color
}
</style>

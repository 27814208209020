<template>
  <div>
    <!-- Tavasuuruses menüü -->
    <div class="navbar-fixed">
      <nav>
        <div class="nav-wrapper">
          <router-link to="/" data-target="mobile-menu" class="sidenav-trigger">
            <span class="hide-on-large-only"><i class="fas fa-bars"></i></span>
          </router-link>
          <a href="/" class="brand-logo">
            <div class="logo valign-wrapper"><img src="@/assets/skemtek-logo.svg"></div>
          </a>
          <ul id="nav-mobile" class="right hide-on-med-and-down">
            <li><a href="#products">{{ $t('navbar.products') }}</a></li>
            <li><a href="#how">{{ $t('navbar.how') }}</a></li>
            <li><a href="#contact">{{ $t('navbar.contact') }}</a></li>
            <li><language-picker></language-picker></li>
          </ul>
        </div>
      </nav>
    </div>

    <!-- Mobiilne menüü -->
    <ul class="sidenav sidenav-close" id="mobile-menu">
      <li><a href="#products">{{ $t('navbar.products') }}</a></li>
      <li><a href="#how">{{ $t('navbar.how') }}</a></li>
      <li><a href="#contact">{{ $t('navbar.contact') }}</a></li>
      <li><div class="divider"></div></li>
      <li class="lang-picker"><language-picker :center="false" :dark-bg="false"></language-picker></li>
    </ul>
  </div>
</template>

<script setup>
import M from 'materialize-css'
import LanguagePicker from '@/components/LanguagePicker'
import {onMounted} from "vue"
  onMounted(() => M.Sidenav.init(document.querySelectorAll('.sidenav'), {closeOnClick: true}))
</script>

<style scoped lang="scss">
a svg.svg-inline--fa {font-size: 1.5rem; height: 1.5rem; vertical-align: sub}
nav a svg.svg-inline--fa, nav a, nav svg.svg-inline--fa, nav a svg.svg-inline--fa {color: white}
.sidenav a, .sidenav svg.svg-inline--fa {color: $text-color}
a:hover {background-color: darken($primary-color, 10%); transition: background-color 1s}
.top-links a {color: white}
.brand-logo {margin-left: 1rem}
a.brand-logo, .logo {height: 100%}
.logo img {height: 28px}
.lang-picker {margin: 0.75rem 0rem -0.75rem 2rem}
@media only screen and (max-width: $small-screen) {.brand-logo {left: 50%; transform: translateX(-50%); position: absolute}}
@media only screen and (min-width: $medium-screen-up) {nav a.sidenav-trigger {display: block;}}//muidu läheb logo suurel ekraanil vastu äärt
</style>

<template>
  <div>
    <top-page/>
    <div class="container">
      <how-it-works/>
      <why-choose/>
      <products/>
    </div>
    <contact/>
  </div>
</template>

<script>
import TopPage from "@/components/TopPage.vue";
import HowItWorks from "@/components/HowItWorks.vue";
import WhyChoose from "@/components/WhyChoose.vue";
import Products from "@/components/Products.vue";
import Contact from "@/components/Contact.vue";

export default {
  name: "NewLandingPage",
  components: {Contact, Products, WhyChoose, HowItWorks, TopPage}
}
</script>

<style scoped>

</style>

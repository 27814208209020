export default {
	navbar: {
		products: 'Products',
		how: 'How it works',
		advantages: 'Advantages',
		contact: 'Contact',
	},
	landing: {
		slogan: 'Take any access gate to the next level with ticket validation module',
		cta: 'Get started',
		shop: 'Shop now',
		info: 'We offer custom integration experience with quick and easy standard module installation'
	},
	how: {
		title: 'How Skemtek Module Works',
		title2: 'Module installation is super easy',
		wireup: 'Wire up',
		wireup2: 'the module',
		settings: 'Set up',
		settings2: 'via your device',
		validate: 'Start',
		validate2: 'validating at the gate',
		info1: 'Skemtek modules add ticket validation feature to an access gate. With the Skemtek module, there is no need for a high-cost and time-consuming R&D process. Wiring up and entering settings via phone or browser is all a technician has to do.',
		info2: 'Customers often demand ticket validation for their access gates. The gates must be integrated either into a POS system, a cloud-based service provider or an enterprise backend system (ERP, microservice). Despite this need, many industrial gates lack such features and integration becomes a headache.',
		info3: 'Stand out from the competition and easily add integration-feature with Skemtek! With fast integration, you can keep costs down.',
	},
	why: {
		title: '8 Reasons to Choose Skemtek Module',
		reason1: 'Reliable and well-tested in many deployments',
		reason2: 'Support modern integration methods: HTTP/JSON, HTTP/XML, REST, SOAP, microservices',
		reason3: 'Little or no R&D is needed - immediately ready for deployment',
		reason4: 'The module offers an intuitive browser-based setup interface for parameter entry',
		reason5: 'The module offers a simple troubleshooting UI, accessible via the web browser',
		reason6: 'The risk of exceeding the delivery deadline is almost non-existent',
		reason7: 'There is no risk of exceeding the budget - modules have a fixed price',
		reason8: 'The Skemtek module is built for a ticket validation only and the on-site wiring amount is minimal',
		title1: 'Well-tested',
		title2: 'Integration-ready',
		title3: 'No R&D needed',
		title4: 'Easy setup',
		title5: 'Simple troubleshooting',
		title6: 'Fixed deadline',
		title7: 'Fixed price',
		title8: 'Minimal wiring',
	},
	contact: {
		title: 'Contact',
		location: 'Kandle 3, Tänassilma, Harjumaa 76406, Estonia',
		legal: 'Miltema OÜ, reg.no 10947918, VAT ID EE100827840',
	},
	vatinfo: '* VAT is not included. Prices are for a quantity of 3-10 pc.',
	products: {
		title: 'Skemtek Product Offers',
		brief1: 'Best for simple gates in office buildings',
		brief2: 'Best for gates in theatres, cinemas & concert halls',
		brief3: 'Best for premium gates with personalized messages',
		order: 'Contact to order',
		customizable: 'customizable',
		customizableSplit: 'custo- mizable',
		feature1: 'Validation services',
		feature2: 'RFID, QR, barcode scanner support (USB)',
		feature3: 'Color LCD display included',
		feature4: 'Animated feedback on display',
		feature5: 'Personal greetings on display',
		feature6: 'HTTPS/JSON, REST, SOAP API integration',
		feature7: 'Microservices integration',
		feature8: 'Power supply included',
		feature9: '10A/250VAC relays for lamps control',
		feature10: 'Intuitive web browser based setup UI',
		feature11: 'Web browser based troubleshooting',
		showAll: 'Show all',
		how: {
			subtitle: 'How GATE modules work',
			link: 'GATE module prices',
		},
	},
	order: {
		title: 'Ordering Modules',
		description: 'Thank you for your interest in Skemtek modules! You can place your order either by phone or by e-mail',
	},
	footer: {
		websocketLost: 'Server connection lost',
	},
}

import { createI18n } from 'vue-i18n'
import et from '@/labels/et'
import en from '@/labels/en'

const messages = {et, en}
const i18n = createI18n({
	globalInjection: true,
	locale: localStorage.getItem('locale') || 'et',
	fallbackLocale: 'en',
	messages
})

function getLocale() {
	return i18n.mode === 'legacy' ? i18n.global.locale : i18n.global.locale.value
}

i18n.setLocale = function(locale) {
	if (i18n.mode === 'legacy')
		i18n.global.locale = locale
	else i18n.global.locale.value = locale
}

i18n.getError = function(code) {
	return messages[getLocale() || 'en'].error[code]
}

i18n.translate = function(key) {
	const langMsg = messages[getLocale() || 'en']
	return langMsg[key] || eval(`langMsg.${key}`)
}

i18n.getLocale = function() {return getLocale()}

export default i18n
